import { Controller } from '@hotwired/stimulus'
import { Turbo } from '@hotwired/turbo-rails'

/*
 * Description
 * -------
 *
 * Forces the browser to go back in history if user came to current page via Turbo or from another page of the application or from the admin dashboard.
 * Must be used on link element.
 * Can be used multiple times in the document.
 *
 * Usage
 * -------
 *
 * <a
 *   href="/supporters"
 *   data-controller="back-button"
 *   data-action="click->back-button#goBack"
 * ></a>
 */

export default class extends Controller {
  goBack(e) {
    if (
      Turbo.navigator.history.currentIndex > 0 ||
      (document.referrer &&
        (new URL(document.referrer).pathname.startsWith('/supporters') ||
          new URL(document.referrer).pathname.startsWith('/admin/dashboard')))
    ) {
      e.preventDefault()
      window.history.back()
    }
  }
}
