import { Controller } from '@hotwired/stimulus'

/*
 * Description
 * -------
 *
 * Handles editing cancellation.
 * Must be used on turbo-frame element.
 * Can be used multiple times in the document.
 *
 * Usage
 * -------
 *
 * <turbo-frame data-controller="comment">
 *   <button data-action="click=>comment#cancelEdit" data-comment-id-param="123">Cancel</button>
 * </turbo-frame>
 */

export default class extends Controller {
  static targets = ['editForm', 'main']

  cancelEdit() {
    this.editFormTarget.classList.add('hidden')
    this.mainTarget.classList.remove('hidden')
  }
}
